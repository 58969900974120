import React from "react";

import { Link } from "gatsby";

import { openPopupWidget } from "react-calendly";

const url = "https://calendly.com/fastlabs/free-consultation";

const CTA = ({ partner }) => (
  <section className="hero is-primary is-bold box">
    <div className="hero-body">
      <div className="container">
        <div className="content has-text-centered">
          <h1 className="title">Accept payments with the speed of real-time</h1>
          <h2 className="subtitle">
            Join the waitlist and we'll get back to you once Ruby is live
          </h2>
          <br />
          <Link to="/contact" className="button is-light is-large is-rounded">
            <strong>Join waitlist</strong>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default CTA;
