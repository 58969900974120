import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Columns } from './bulma';

export default function Faq({ category, items, size }) {
	const data = useStaticQuery(FAQ);
	const { edges: posts } = data.allMarkdownRemark;

	return (
		<Columns multiline>
			{posts
				.slice(0, items || 100)
				.filter(({ node }) => (category ? node.frontmatter.category === category : node))
				.map(({ node: post }) => (
					<div className={`is-parent column is-${size}`} key={post.id}>
						<article className={`blog-list-item tile is-child notification`}>
							<header>
								{post.frontmatter.featuredimage ? (
									<figure className="image">
										<PreviewCompatibleImage
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${post.title}`
											}}
										/>
									</figure>
								) : null}
								<p className="post-meta">
									<Link className="title is-size-4" to={post.fields.slug}>
										{post.frontmatter.title}
									</Link>
								</p>
							</header>
							<p>
								{post.excerpt}
								<br />
								<br />
								<Link className="button is-primary" to={post.fields.slug}>
									Keep Reading →
								</Link>
							</p>
						</article>
					</div>
				))}
		</Columns>
	);
}

Faq.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array
		})
	})
};

const FAQ = graphql`
	query FaqQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { templateKey: { eq: "faq-article" } } }
		) {
			edges {
				node {
					excerpt(pruneLength: 200)
					id
					fields {
						slug
					}
					frontmatter {
						title
						description
						category
						templateKey
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 120, quality: 100) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`;
