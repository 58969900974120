import React from 'react';
import PreviewCompatibleImage from './PreviewCompatibleImage';
// import Img from 'gatsby-image';
import { Link } from 'gatsby';
const Hero = ({ size, color, bold, title, subtitle, image, signup, cta, link, partner }) => (
	<section className={`hero is-small`}>
		<div className="hero-body">
			<div className="columns is-mobile is-centered">
				<div className="column is-half-desktop has-text-centered">
					<div className="has-text-centered">
						<h1 className="title is-size-1 has-text-weight-bold">{title}</h1>
						<h2 className="subtitle is-size-4">{subtitle}</h2>

						{signup && (
							<div className="buttons is-centered">
								<p className="control">
									<a href={`https://app.payment.ninja/signup`} className="button is-primary is-large">
										<strong>START FREE</strong>
									</a>
								</p>
								<p className="control">
									<Link to="/savings-calculator" className="button is-text is-large">
										Estimate savings
									</Link>
								</p>
								{/* <p className="control">
							<a
								href="https://calendly.com/finhub/payment-ninja-demo"
								className="button is-text is-large"
							>
								Free consultation
							</a>
						</p> */}
							</div>
						)}
						{cta && (
							<Link className="button is-primary is-medium" to={link}>
								<strong>{cta} →</strong>
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default Hero;
